import React from "react";
import PropTypes from "prop-types";
import ReactLoading from "react-loading";
import { Modal } from "../Modal";
import "./style.scss";

const LoadingContext = React.createContext({
  setOpen: () => {},
  setMessage: () => {},
});

const { Provider } = LoadingContext;

export const useLoading = () => {
  const ctx = React.useContext(LoadingContext);

  return {
    openLoading: ctx.setOpen,
    setMessage: ctx.setMessage,
  };
};

const Loading = ({ open, message = "Espere, por favor..." }) => (
  <>
    {open && (<Modal isOpen={open} hasContainer={false}>
      <ReactLoading type="spin" width={125} height={125} />
      <p className="spinner__text">{message}</p>
    </Modal>)}
  </>
);

Loading.prototype = {
  open: PropTypes.bool.isRequired,
};

export const LoadingProvider = ({ children }) => {
  const [message, setMessage] = React.useState("Espere, por favor...");
  const [isOpen, setOpen] = React.useState(false);

  return (
    <Provider
      value={{
        setOpen,
        setMessage,
      }}
    >
      <Loading open={isOpen} message={message} />
      {children}
    </Provider>
  );
};

export default Loading;

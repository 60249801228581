import React from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";
import { store } from "store/authStore";
import { ROUTES_CONFIG } from "utils/config";

const Public = ({ component: Component, ...rest }) => {
  const {
    state: {
      auth: { userHasAuthenticated },
    },
  } = React.useContext(store);

  return (
    <Route
      {...rest}
      component={(props) =>
        userHasAuthenticated ? <Redirect to={ROUTES_CONFIG.dashboard} /> : <Component {...props} />
      }
    />
  );
};

Public.propTypes = {
  component: PropTypes.elementType.isRequired,
};

const Private = ({ component: Component, ...rest }) => {
  const {
    state: {
      auth: { userHasAuthenticated },
    },
  } = React.useContext(store);

  return (
    <Route
      {...rest}
      render={(props) => (userHasAuthenticated ? <Component {...props} /> : <Redirect to={ROUTES_CONFIG.login} />)}
    />
  );
};

Private.propTypes = {
  component: PropTypes.elementType.isRequired,
};

export { Public, Private };

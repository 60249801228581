import React, { lazy, useContext, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { store } from "store/authStore";
import { Public as PublicRoute, Private as PrivateRoute } from "pages/types";
import { ROUTES_CONFIG } from "utils/config";
import { COOKIES_CONFIG } from "utils/config";

const Login = lazy(() => import("pages/Login"));
const Recover = lazy(() => import("pages/RecoverPassword"));
const Reset = lazy(() => import("pages/ResetPassword"));
const Account = lazy(() => import("pages/Account"));
const Dashboard = lazy(() => import("pages/Dashboard"));
const Users = lazy(() => import("pages/Users"));
const NotFound = lazy(() => import("pages/NotFound"));

const Fallback = <p style={{ marginLeft: "2rem" }}>Loading...</p>;

function App() {
  const { dispatch } = useContext(store);

  useEffect(() => {
    const token = sessionStorage.getItem(COOKIES_CONFIG.token);
    const userData = sessionStorage.getItem(COOKIES_CONFIG.userData);
    if (token && userData) {
      dispatch({
        type: "login",
        payload: {
          userData: userData,
          userHasAuthenticated: true,
        },
      });
    }
  }, [dispatch]);

  return (
    <Router>
      <React.Suspense fallback={Fallback}>
        <Switch>
          <PublicRoute exact path={ROUTES_CONFIG.login} component={Login} />
          <PublicRoute path={ROUTES_CONFIG.recover} component={Recover} />
          <PublicRoute path={`${ROUTES_CONFIG.reset}/:token`} component={Reset} />
          <PrivateRoute path={ROUTES_CONFIG.dashboard} component={Dashboard} />
          <PrivateRoute path={ROUTES_CONFIG.account} component={Account} />
          <PrivateRoute path={ROUTES_CONFIG.users} component={Users} />
          <Route component={NotFound} />
        </Switch>
      </React.Suspense>
    </Router>
  );
}

export { App };

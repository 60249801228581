import "react-app-polyfill/ie11";
import "core-js";
import "regenerator-runtime/runtime";
import "polyfill-array-includes";
import React from "react";
import ReactDOM from "react-dom";
import { StateProvider as AuthProvider } from "store/authStore.js";
import { StateProvider as DataProvider } from "store/dataStore.js";
import { StateProvider as MapProvider } from "store/mapStore.js";
import { StateProvider as UiProvider } from "store/uiStore.js";
import { LocalizationProvider } from "@material-ui/pickers";
import DateFnsUtils from "@material-ui/pickers/adapter/date-fns";
import { App as Main } from "App";
import "./index.scss";
import { LoadingProvider } from "components/UI/Loading";

const App = (
  <AuthProvider>
    <DataProvider>
      <UiProvider>
        <MapProvider>
          <LocalizationProvider dateAdapter={DateFnsUtils}>
            <LoadingProvider>
              <Main />
            </LoadingProvider>
          </LocalizationProvider>
        </MapProvider>
      </UiProvider>
    </DataProvider>
  </AuthProvider>
);

ReactDOM.render(App, document.getElementById("root"));

import React, { createContext, useReducer } from "react";
import PropTypes from "prop-types";

const KPIS_FACTORY = () => ({
  accumulated: {
    name: "",
    id: 0,
    fact_sum_sucursal: 0,
    transacc_sum_sucursal: 0,
    cards_sum_sucursal: 0,
    freq_compra: 0,
  },
  accumulatedZonas: {
    departamentos: {
      fact_sum_sucursal: 0,
      transacc_sum_sucursal: 0,
      cards_sum_sucursal: 0,
      freq_compra: 0,
      name: "",
    },
    municipios: {
      fact_sum_sucursal: 0,
      transacc_sum_sucursal: 0,
      cards_sum_sucursal: 0,
      freq_compra: 0,
      name: "",
    },
    colonias: {
      fact_sum_sucursal: 0,
      transacc_sum_sucursal: 0,
      cards_sum_sucursal: 0,
      freq_compra: 0,
      name: "",
    },
  },
  ids: [1001200013003],
  dates: "",
});

const initialState = {
  groupSelected: "",
  comercios: {},
  sucursalesAccumulated: [],
  zonasAccumulated: [],
  selectedComercio: {
    0: {
      id: "",
      name: "",
      sucursales: {},
      zonas: {},
    },
  },
  zonas: {
    municipio: "",
    departamento: "",
    colonia: "",
  },
  accumulatedComercio: {
    fact_sum_comercio: null,
    transacc_sum_comercio: null,
    cards_sum_comercio: null,
  },
  kpisData: KPIS_FACTORY(),
  kpisDataComparative: KPIS_FACTORY(),
  typeFacturacion: "fact_sum_sucursal",
  typeColonia: "municipios",
  isSucursalSelect: true,
  timeFacturacion: "mes",
  legend: {
    min: 0,
    max: 0,
  },
  rubroPosSelect: ""
};

const store = createContext(initialState);
const { Provider } = store;

const StateProvider = ({ children }) => {
  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case "set selected Group":
        return { ...state, groupSelected: action.payload };
      case "set Comercios":
        return { ...state, comercios: action.payload };
      case "set Zonas":
        return { ...state, zonas: action.payload };
      case "set Sucursales":
        return { ...state, sucursales: action.payload };
      case "set selected Comercio":
        return { ...state, selectedComercio: action.payload };
      case "set selected Comercio accumulated":
        return { ...state, accumulatedComercio: action.payload };
      case "set selected Sucursales accumulated":
        return { ...state, sucursalesAccumulated: action.payload };
      case "set selected Zonas accumulated":
        return { ...state, zonasAccumulated: action.payload };
      case "set kpis data":
        return { ...state, kpisData: { ...state.kpisData, [action.payload.type]: action.payload.value } };
      case "set kpis data comparative":
        return {
          ...state,
          kpisDataComparative: { ...state.kpisDataComparative, [action.payload.type]: action.payload.value },
        };
      case "set type Facturacion":
        return { ...state, typeFacturacion: action.payload };
      case "set type Colonia":
        return { ...state, typeColonia: action.payload };
      case "set is Sucursal select":
        return { ...state, isSucursalSelect: action.payload };
      case "set time facturacion":
        return { ...state, timeFacturacion: action.payload };
      case "set min, max legend":
        return { ...state, legend: action.payload };
      case "set rubro pos select":
        return { ...state, rubroPosSelect: action.payload };
      default:
        return state;
    }
  }, initialState);

  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

StateProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export { store, StateProvider, KPIS_FACTORY };

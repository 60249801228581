import React from "react";
import "./style.scss";

function Modal({ children, isOpen = false, hasContainer = true }) {
  return (
    <>
      <div className={`modal__bg ${isOpen ? "is-open" : ""}`}>
        {hasContainer ? (
          <div className={`modal ${isOpen ? "is-open" : ""}`}>
            <div className="modal__container">
              {children && Array.isArray(children) ? children.map((child) => child) : children}
            </div>
          </div>
        ) : (
          <div className="modal__no-container">
            {children && Array.isArray(children) ? children.map((child) => child) : children}
          </div>
        )}
      </div>
    </>
  );
}

export { Modal };

import React, { createContext, useReducer } from "react";
import PropTypes from "prop-types";

const initialState = {
  firstSidebarVisibility: false,
  secondSidebarVisibility: false,
  settingsPanelVisibility: false,
  openModalDelete: false,
  account: {
    containerLeft: 0,
  },
  openGeocoder: false,
};

const store = createContext(initialState);
const { Provider } = store;

const StateProvider = ({ children }) => {
  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case "set kpis sidebar visibility - first":
        return { ...state, firstSidebarVisibility: action.payload };
      case "set kpis sidebar visibility - second":
        return { ...state, secondSidebarVisibility: action.payload };
      case "set settings visiblity":
        return { ...state, settingsPanelVisibility: action.payload };
      case "set modal delete user":
        return { ...state, openModalDelete: action.payload };
      case "set visibility geocoder":
        return { ...state, openGeocoder: action.payload };
      case "set account UI":
        return {
          ...state,
          account: {
            ...state.account,
            [action.payload.type]: action.payload.value,
          },
        };
      default:
        return state;
    }
  }, initialState);

  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

StateProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export { store, StateProvider };

export const COOKIES_CONFIG = {
  startData: "start_date",
  token: "token",
  tokenCreation: "token_creation",
  tokenRefreshLifespan: "token_refresh_lifespan",
  tokenAccessLifespan: "token_access_lifespan",
  userData: "user_data",
};

export const ROUTES_CONFIG = {
  login: "/",
  dashboard: "/dashboard",
  users: "/users",
  recover: "/recover",
  reset: "/recover_password",
  account: "/account",
};

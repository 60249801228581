import React, { createContext, useReducer } from "react";
import PropTypes from "prop-types";

const INITIAL_LAYERS_VISIBILITY = {
  municipios: "visible",
  colonias: "none",
  departamentos: "none",
};

const initialState = {
  dataLoading: true,
  forceTilesUpdate: false,
  viewport: {
    center: [-86.4, 14.81],
    zoom: [7.3],
  },
  bounds: [
    [-87.36, 14.26],
    [-87.27, 14.34],
  ],
  sucursalesMarker: null,
  paints: {
    departamentos: {},
    municipios: { "fill-color": "transparent" },
    colonias: {},
    departamentos_line: { "line-color": "transparent", "line-width": 3 },
    municipios_line: { "line-color": "transparent", "line-width": 3 },
    colonias_line: { "line-color": "transparent", "line-width": 3 },
  },
  markerFeature: {
    type: "Feature",
    geometry: {
      type: "Point",
      coordinates: [0, 0],
    },
  },
  layersVisibility: INITIAL_LAYERS_VISIBILITY,
};

function layersSwitcher(state, layerToShow) {
  let newState = {};

  for (const layer of Object.keys(state)) {
    newState = {
      ...newState,
      [layer]: layer === layerToShow ? "visible" : "none",
    };
  }

  return newState;
}

const store = createContext(initialState);
const { Provider } = store;

const StateProvider = ({ children }) => {
  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case "force tiles update":
        return { ...state, forceTilesUpdate: action.payload };
      case "set loading":
        return { ...state, dataLoading: action.payload };
      case "set viewport":
        return { ...state, viewport: { ...state.viewport, [action.payload.type]: action.payload.value } };
      case "set sucursales marker":
        return { ...state, sucursalesMarker: action.payload };
      case "set filter":
        return { ...state, filters: { ...state.filters, [action.payload.type]: action.payload.value } };
      case "set marker geometry":
        return {
          ...state,
          markerFeature: {
            ...state.markerFeature,
            geometry: { ...state.markerFeature.geometry, coordinates: action.payload },
          },
        };
      case "set paint property":
        const { layer, name, value } = action.payload;
        return {
          ...state,
          paints: {
            ...state.paints,
            [layer]: {
              ...state.paints[layer],
              [name]: value,
            },
          },
        };
      case "set layers visibility":
        return { ...state, layersVisibility: layersSwitcher(state.layersVisibility, action.payload) };
      default:
        return state;
    }
  }, initialState);

  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

StateProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export { store, StateProvider };
